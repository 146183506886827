<template>
  <a-form layout="inline" :form="form">
    <a-form-item class="m-bottom-base">
      <span class="m-right-sm">授权次数</span>
      <a-radio-group button-style="solid" v-decorator="['auth_num']">
        <a-radio-button
          v-for="item in AuthNumberOptions"
          :value="item.value"
          :key="item.value"
        >{{item.label}}</a-radio-button>
      </a-radio-group>
    </a-form-item>

    <a-form-item class="m-bottom-base">
      <span class="m-right-sm">授权渠道</span>

      <a-radio-group button-style="solid" v-decorator="['use_mode']">
        <a-radio-button
          v-for="item in AuthChannelOptions"
          :value="item.value"
          :key="item.value"
        >{{item.label}}</a-radio-button>
      </a-radio-group>
    </a-form-item>

    <a-form-item class="m-bottom-base">
      <span class="m-right-sm">授权区域</span>

      <a-radio-group button-style="solid" v-decorator="['auth_area']">
        <a-radio-button
          v-for="item in AuthAreaOptions"
          :value="item.value"
          :key="item.value"
        >{{item.label}}</a-radio-button>
      </a-radio-group>
    </a-form-item>

    <a-form-item class="m-bottom-base">
      <span class="m-right-sm">授权期限</span>

      <a-radio-group button-style="solid" v-decorator="['auth_time']">
        <a-radio-button
          v-for="item in AuthTimeOptions"
          :value="item.value"
          :key="item.value"
        >{{item.label}}</a-radio-button>
      </a-radio-group>
    </a-form-item>
  </a-form>
</template>

<script>
  import {AuthAreaOfMusicOptions, AuthTimeOptions} from '@/config/options';

  const AuthNumber = [
    {
      label: '5首',
      value: 5
    },
    {
      label: '10首',
      value: 10
    },
    {
      label: '20首',
      value: 20
    },
    {
      label: '40首',
      value: 40
    },
  ]

  export default {
    data() {
      return {
        AuthNumberOptions: AuthNumber,
        AuthChannelOptions: [],
        AuthAreaOptions: [],
        AuthTimeOptions: []
      }
    },
    beforeCreate() {
      this.form = this.$form.createForm(this, {
        onValuesChange: (props, value, values) => {
          this.$emit('change', values);
        }
      })
    },

    created() {
      // this.$root.getCommonSceneAndMode().then(data => {
      //   console.log(data);
      // })
    },

    // mounted() {
    //   this.initFormDefaultValue();
    // },

    watch: {
      currData: {
        handler(newVal = {}) {
          if (newVal && Object.keys(newVal).length > 0) {
            let {auth_area = [], auth_time = [], use_mode = []} = newVal;

            auth_area = auth_area.filter(item => item.is_enabled == '1').map(({type}) => type);
            auth_time = auth_time.filter(item => item.is_enabled == '1').map(({type}) => type);

            this.AuthChannelOptions = [].concat(use_mode.map(({name}) => ({value: name, label: name})));
            this.AuthAreaOptions = [].concat(AuthAreaOfMusicOptions.filter(item => ~auth_area.indexOf(item.value)))
            this.AuthTimeOptions = [].concat(AuthTimeOptions.filter(item => ~auth_time.indexOf(item.value)))

            this.$nextTick(() => {
              this.initFormDefaultValue();
            })
          }
        },
        immediate: true
      }
    },

    props: {
      auth_type: String,
      currData: Object,
    },

    methods: {
      initFormDefaultValue() {
        let def = {};
        let {AuthNumberOptions: an, AuthChannelOptions: ac, AuthAreaOptions: ae, AuthTimeOptions: at} = this;

        def.auth_num = an && an.length > 0 ? an[0].value : '';
        def.use_mode = ac && ac.length > 0 ? ac[0].value : '';
        def.auth_area = ae && ae.length > 0 ? ae[0].value : '';
        def.auth_time = at && at.length > 0 ? at[0].value : '';

        this.form.setFieldsValue(def);
      },

      calcPrice(values) {
        let {AuthNumberOptions: an, AuthChannelOptions: ac, AuthAreaOptions: ae, AuthTimeOptions: at} = this;
        let {auth_num, use_mode, auth_area, auth_time} = values;


      }
    }
  }
</script>

<style lang="scss" scoped>
  @media screen and (max-width: $screen-width-md) {
    .ant-form-inline {
      .ant-form-item {
        display: block;
      }
    }
  }
</style>
