<template>
  <div class="song-list">
    <div class="container">
      <page-title title="超值套餐" sub="超低折扣/无水印下载/专享客服"></page-title>
    </div>
    <div class="song-list-content">
      <div class="container">
        <a-tabs v-model="curActiveKey">
          <a-tab-pane v-for="item in dataList" :tab="item.name" :key="item.auth_scene"></a-tab-pane>
        </a-tabs>

        <filter-package :currData="currentData" @change="onChangeFilterPackage" class="m-bottom-xs"></filter-package>

        <template v-if="curPackages && curPackages.length > 0">
          <a-row
            class="song-list-detail m-bottom-lg"
            v-for="item in curPackages"
            :key="item.goods_id"
          >
            <a-col class="detail-item first" :xs="24" :sm="24" :md="8">
              <div class="flex cen-start top-title m-bottom-md">
                <img src="@/assets/images/diamond.png" class="m-right-sm" alt />
                <span class="font-heading-2">{{item.name}}·{{item.auth_num}}首</span>
              </div>
              <div class="m-bottom-xs">
                <span class="font-color-sub m-right-xs">授权渠道</span>
                <span>{{item.use_mode}}</span>
              </div>
              <div class="m-bottom-xs">
                <span class="font-color-sub m-right-xs">授权区域</span>
                <span>{{item.auth_area | toArea}}</span>
              </div>
              <div class="m-bottom-md">
                <span class="font-color-sub m-right-xs">授权期限</span>
                <span>{{item.auth_time | toAuthPeriod}}</span>
              </div>
              <div class="last-item">
                <span>福利</span>
                <span>可下载{{item.download_num}}首无水印音乐用于试听</span>
              </div>
            </a-col>
            <a-col class="detail-item second" :xs="24" :sm="24" :md="8">
              <div class="flex cen-start m-bottom-base">
                <icon-font type="iconcorrect-empty" class="icon-font"></icon-font>
                <span>支持公对公付款</span>
              </div>
              <div class="flex cen-start m-bottom-base">
                <icon-font type="iconcorrect-empty" class="icon-font"></icon-font>
                <span>支持签署纸质合同</span>
              </div>
              <div class="flex cen-start m-bottom-md">
                <icon-font type="iconcorrect-empty" class="icon-font"></icon-font>
                <span>支持专享客服在线协助选曲</span>
              </div>

              <div class="font-color-sub">{{item.desc}}</div>
            </a-col>
            <a-col class="detail-item last" :xs="24" :sm="24" :md="8">
              <div class="m-bottom-sm">
                <b class="m-right-xs">折后每首</b>
                <b>¥{{div(mul(calcOrginPrice(item), div(item.discount_ratio, 100)), item.auth_num)}}</b>
              </div>
              <div class="text-line-through m-bottom-sm">
                <b class="m-right-xs">套餐原价</b>
                <b>¥{{calcOrginPrice(item)}}</b>
              </div>

              <div
                class="price m-bottom-base font-color-primary"
              >¥{{mul(calcOrginPrice(item), div(item.discount_ratio, 100))}}</div>

              <a-button class="w-100" size="large" type="primary" @click="goBuyPackage(item)">立即购买</a-button>

              <div class="discount-tip">{{(item.discount_ratio / 10).toFixed(1)}}折</div>
            </a-col>
          </a-row>
        </template>
        <template v-else>
          <a-empty style="margin-top: 100px;"></a-empty>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import {Empty} from 'ant-design-vue'
  import PageTitle from '@/pages/components/common/PageTitle.vue';
  import FilterPackage from './components/FilterPackage.vue'

  import {add, mul, div} from '@/utils/from-common';

  import {BusinessSceneOptions} from '@/config/options';

  export default {
    data() {
      return {
        showDialog: false,
        BusinessSceneOptions: BusinessSceneOptions,
        curActiveKey: '',
        dataList: [],

        add, mul, div,

        curFilterParams: {}

      }
    },
    components: {
      PageTitle,
      FilterPackage,
      AEmpty: Empty
    },
    computed: {
      currentData() {
        return this.dataList.filter(item => item.auth_scene == this.curActiveKey)[0] || {}
      },

      curPackages() {
        let params = this.curFilterParams;
        let keys = Object.keys(params);

        if (!keys || keys.length === 0) return []

        let data = this.currentData.goods || [];

        return data.filter(function (item) {
          let bool = keys.every(function (iKey) {
            return typeof params[iKey] !== 'undefined' && params[iKey] == item[iKey]
          })

          return bool
        })
      }
    },
    created() {
      let params = {};

      this.$axios.Package_List(params).then(res => {
        const data = res.data;

        if (data && data.code == 0) {
          let list = data.data ? data.data.list : [];

          this.dataList = list;
          this.curActiveKey = list[0].auth_scene
        }
      })
    },
    methods: {
      onChangeFilterPackage(values) {
        values.auth_scene = this.curActiveKey;

        this.curFilterParams = Object.assign({}, values);
      },

      calcOrginPrice(item) {
        let {auth_area_ratio = 0, auth_time_ratio = 0, use_mode_price = 0, auth_num = 0} = item;

        let ratio1 = div(auth_area_ratio, 100);
        let ratio2 = div(auth_time_ratio, 100);

        return mul(mul(ratio1, ratio2), mul(use_mode_price, auth_num));
      },

      onChangeTabs() {

      },


      goBuyPackage(item) {
        let {goods_id, auth_scene, use_mode, auth_time, auth_area, valid_time, auth_num, download_num, discount_ratio} = item;

        let params = {goods_id, auth_scene, use_mode, auth_time, auth_area, valid_time, auth_num, download_num, discount_ratio};

        this.$store.commit('changeContentLoading', true);

        this.$axios.Package_orderAdd(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let orderDetail = data.data

            this.$router.push(`/paypackage/${orderDetail.order_id}`);
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .song-list {
    padding-bottom: 40px;
  }

  .song-list-content {
    background-color: #fff;
  }

  .song-list-detail .detail-item {
    height: 230px;

    &.first {
      color: #fff;
      background-color: #2e3557;

      .top-title {
        height: 34px;

        > img {
          height: 32px;
          width: 32px;
        }
      }

      .last-item {
        > span {
          padding: 4px 8px;
          border: 1px solid rgba($color: #fff, $alpha: 0.12);

          &:last-child {
            border-left: none;
          }
        }
      }
    }

    &.second {
      /deep/ .icon-font {
        svg {
          width: 24px;
          height: 24px;
          fill: $success-color;
          margin-right: 5px;
        }
      }
    }

    &.last {
      .price {
        font-size: 32px;
        font-weight: bold;
      }

      .discount-tip {
        position: absolute;
        top: 0;
        right: 20px;
        padding: 3px 10px;
        color: #fff;
        background-color: #2e3557;
        font-size: $font-size-xl;
      }
    }

    &:not(.first) {
      border: 1px solid $border-color;
    }
  }

  @media screen and(min-width: $screen-width-md) {
    .song-list-detail .detail-item {
      padding: 24px 20px;
    }
  }

  @media screen and(min-width: $screen-width-xl) {
    .song-list-detail .detail-item {
      padding: 24px 40px;
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .song-list-detail .detail-item {
      padding: 24px 1rem;

      &:last-child {
        border-top: none;
      }
    }
  }
</style>
