<template>
  <div class="page-title">
    <div class="font-heading-2 m-bottom-xs font-bold">{{title}}</div>
    <div>{{sub}}</div>
  </div>
</template>

<script>
  export default {
    props: {
      title: String,
      sub: String,
    },
  }
</script>

<style lang="scss" scoped>
  .page-title {
    padding: 32px 0 24px;
  }
</style>
